<template>
  <div class="home" style="background-color: aliceblue">
    <Header></Header>
    <div class="container mt-2">
      <div class="row">
        <div class="col-md-6 d-flex flex-column justify-content-center">
          <h2 class="mt-2">Selamat Datang!</h2>
          <h1 class="my-2">Peternak Pintar Terintegrasi (PETIR)</h1>
          <p class="fs-6">
            Selamat datang di Laman Peternak Pintar Terintegrasi (PETIR). Laman
            ini diperuntukkan untuk mengelola data peternakan yang ada di
            Kabupaten Tojo Una-Una.
          </p>
          <!-- <router-link class="my-4 btn btn-warning" to="/"
            ><font-awesome-icon icon="sign-in" /> LOGIN</router-link
          > -->
        </div>
        <div
          class="col-md-5 d-flex flex-column justify-content-center items-center"
        >
          <img
            src="../../assets/image-hero.png"
            class="img-fluid d-none d-md-block"
            alt="Sample image"
          />
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
  },
  methods: {
    openForm(param) {
      // Membuat objek untuk memetakan param ke route
      const routeMap = {
        1: "formulir-1",
        2: "formulir-2",
        3: "formulir-3",
        4: "formulir-4",
      };

      if (routeMap[param]) {
        this.$router.push(routeMap[param]);
      }
    },
  },
};
</script>

<style scoped>
.home {
  min-height: 100vh;
}
.img-fluid {
  margin: auto;
  height: 75%;
}
</style>
