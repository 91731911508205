<template>
  <div id="layoutSidenav_nav">
    <nav
      class="sb-sidenav accordion bg-light"
      id="sidenavAccordion"
      style="box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)"
    >
      <div class="sb-sidenav-menu">
        <div class="nav">
          <div class="sb-sidenav-menu-heading">Menu</div>
          <router-link
            v-for="item in flatMenuItems"
            :key="item.link"
            class="nav-link"
            :to="item.to"
            :class="{
              'active bg-active': link == item.link,
            }"
          >
            <div class="sb-nav-link-icon">
              <font-awesome-icon :icon="item.icon" />
            </div>
            <span>{{ item.label }}</span>
          </router-link>

          <div v-for="item in nestedMenuItems" :key="item.link">
            <a
              class="nav-link collapsed"
              href="javascript:;"
              @click="toggleMenu(item)"
              :aria-expanded="isMenuOpen(item)"
              :aria-controls="item.link"
            >
              <div class="sb-nav-link-icon">
                <font-awesome-icon :icon="item.icon" />
              </div>
              {{ item.label }}
              <div class="sb-sidenav-collapse-arrow">
                <font-awesome-icon
                  :icon="isMenuOpen(item) ? 'angle-up' : 'angle-down'"
                />
              </div>
            </a>
            <div
              class="collapse"
              :class="{ show: isMenuOpen(item) }"
              :id="item.link"
              data-bs-parent="#sidenavAccordionPages"
            >
              <nav class="sb-sidenav-menu-nested nav">
                <router-link
                  v-for="child in item.children"
                  :key="child.link"
                  class="nav-link"
                  :to="child.to"
                  :class="{
                    'active bg-active': link == child.link,
                  }"
                >
                  {{ child.label }}
                </router-link>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: "SideBar",
  data() {
    return {
      link: "",
      isMasterMenuOpen: false,
      fullName: "User",
      userName: "User",
      userLevel: "user",
      openMenus: [], // To keep track of which menus are open
      menuItems: [
        {
          link: "dashboard",
          to: "/dashboard",
          icon: "tachometer-alt",
          label: "Dashboard",
          userLevels: ["petugas", "admin"],
        },
        {
          link: "peternak",
          to: "/peternak",
          icon: "users",
          label: "Peternak",
          userLevels: ["petugas", "admin"],
        },
        {
          link: "anak-ternak",
          to: "/anak-ternak",
          icon: "horse",
          label: "Anak Ternak",
          userLevels: ["petugas", "admin"],
        },
        {
          link: "penyakit-ternak",
          to: "/penyakit-ternak",
          icon: "cow",
          label: "Penyakit Ternak",
          userLevels: ["petugas", "admin"],
        },
        {
          link: "pertumbuhan-ternak",
          to: "/pertumbuhan-ternak",
          icon: "chart-line",
          label: "Pertumbuhan Ternak",
          userLevels: ["petugas", "admin"],
        },
        {
          link: "inseminasi-buatan",
          to: "/inseminasi-buatan",
          icon: "dna",
          label: "Inseminasi Buatan",
          userLevels: ["petugas", "admin"],
        },
        {
          link: "konsultasi",
          to: "/konsultasi",
          icon: "comments",
          label: "Konsultasi",
          userLevels: ["petugas", "admin"],
        },
        {
          link: "master",
          icon: "database",
          label: "Master",
          userLevels: ["admin"],
          children: [
            {
              link: "master-penyakit",
              to: "/master-penyakit",
              label: "Master Penyakit",
              userLevels: ["admin"],
            },
            {
              link: "master-jenis-ternak",
              to: "/master-jenis-ternak",
              label: "Master Jenis Ternak",
              userLevels: ["admin"],
            },
          ],
        },
        {
          link: "pengguna",
          to: "/pengguna",
          icon: "file-alt",
          label: "Pengguna",
          userLevels: ["admin"],
        },
      ],
    };
  },
  computed: {
    flatMenuItems() {
      return this.menuItems.filter(
        (item) => !item.children && item.userLevels.includes(this.userLevel)
      );
    },
    nestedMenuItems() {
      return this.menuItems.filter(
        (item) => item.children && item.userLevels.includes(this.userLevel)
      );
    },
  },
  created() {
    const user = localStorage.getItem("userData");
    if (user) {
      this.user = JSON.parse(user);
      this.fullName = this.user.nama;
      this.userName = this.user.username;
      this.userLevel = this.user.level;
    }
  },
  methods: {
    toggleMenu(item) {
      const index = this.openMenus.indexOf(item.link);
      if (index > -1) {
        this.openMenus.splice(index, 1);
      } else {
        this.openMenus.push(item.link);
      }
    },
    isMenuOpen(item) {
      return this.openMenus.includes(item.link);
    },
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newValue) {
        const parts = newValue.split("/");
        const link = parts[1];
        this.link = link;
      },
    },
  },
};
</script>
<style scoped>
.nav-link {
  border-radius: 10px;
  margin: 2px 5px;
  color: #2d2d2e;
}
.nav-link:hover {
  color: #0099ff;
}
.bg-active {
  background-color: #bfdef3;
  color: #0099ff;
  /* background-color: #0093dd; */
  transition: width 0.5s ease-in-out, background-color 0.5s linear;
}
</style>

