<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-scroll bg-white shadow-sm py-1">
      <div class="container">
        <img
          src="../../assets/logo-text.png"
          alt="Logo"
          height="50"
          class="d-inline-block align-text-top my-2"
        />
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <font-awesome-icon icon="bars" />
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav ms-auto">
            <li>
              <router-link class="nav-link open" to="/login">LOGIN</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: "HeaderPage",

  data() {
    return {
      link: "home",
      subtitle: "",
    };
  },
};
</script>
<style scoped>
.nav-link {
  border-radius: 5px;
  padding: 0.65em;
  margin: 2px 5px;
  min-width: 150px;
  text-align: center;
}
.nav-link:hover {
  background-color: #bfdef3;
  color: #0099ff;
}
.open {
  background-color: #51b0f0;
  color: #fff;
  font-weight: 400;
  /* text-decoration: underline; */
  text-underline-position: under;
}
</style>