import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Home from "../pages/landing-page/Home.vue";
import Login from "../pages/Login.vue";

const routes = [
  { path: "/", component: Home },
  {
    path: "*",
    component: () => import("../pages/NotFound.vue"),
  },
  { path: "/home", component: Home },
  {
    path: "/forbidden/",
    component: () => import("../pages/Forbidden.vue"),
  },
  { path: "/dashboard/", component: () => import("../pages/Dashboard.vue") },
  { path: "/login/", component: Login },

  //konsultasi
  {
    path: "/konsultasi/",
    component: () => import("../pages/konsultasi/List.vue"),
  },
  {
    path: "/konsultasi/create",
    component: () => import("../pages/konsultasi/Form.vue"),
  },
  {
    path: "/konsultasi/update/:id",
    component: () => import("../pages/konsultasi/Update.vue"),
  },
  {
    path: "/konsultasi/detail/:id",
    component: () => import("../pages/konsultasi/Detail.vue"),
  },
  //inseminasi
  {
    path: "/inseminasi-buatan/",
    component: () => import("../pages/inseminasi/List.vue"),
  },
  {
    path: "/inseminasi-buatan/create",
    component: () => import("../pages/inseminasi/Form.vue"),
  },
  {
    path: "/inseminasi-buatan/update/:id",
    component: () => import("../pages/inseminasi/Update.vue"),
  },
  {
    path: "/inseminasi-buatan/detail/:id",
    component: () => import("../pages/inseminasi/Detail.vue"),
  },
  //pertumbuhan ternak
  {
    path: "/pertumbuhan-ternak/",
    component: () => import("../pages/pertumbuhan-ternak/List.vue"),
  },
  {
    path: "/pertumbuhan-ternak/create",
    component: () => import("../pages/pertumbuhan-ternak/Form.vue"),
  },
  {
    path: "/pertumbuhan-ternak/update/:id",
    component: () => import("../pages/pertumbuhan-ternak/Update.vue"),
  },
  {
    path: "/pertumbuhan-ternak/detail/:id",
    component: () => import("../pages/pertumbuhan-ternak/Detail.vue"),
  },
  //master-jenis-ternak
  {
    path: "/master-jenis-ternak/",
    component: () => import("../pages/master-jenis-ternak/List.vue"),
  },
  {
    path: "/master-jenis-ternak/create",
    component: () => import("../pages/master-jenis-ternak/Form.vue"),
  },
  {
    path: "/master-jenis-ternak/update/:id",
    component: () => import("../pages/master-jenis-ternak/Update.vue"),
  },
  //master-penyakit
  {
    path: "/master-penyakit/",
    component: () => import("../pages/master-penyakit/List.vue"),
  },
  {
    path: "/master-penyakit/create",
    component: () => import("../pages/master-penyakit/Form.vue"),
  },
  {
    path: "/master-penyakit/update/:id",
    component: () => import("../pages/master-penyakit/Update.vue"),
  },
  //penyakit-ternak
  {
    path: "/penyakit-ternak/",
    component: () => import("../pages/penyakit-ternak/List.vue"),
  },
  {
    path: "/penyakit-ternak/create",
    component: () => import("../pages/penyakit-ternak/Form.vue"),
  },
  {
    path: "/penyakit-ternak/update/:id",
    component: () => import("../pages/penyakit-ternak/Update.vue"),
  },
  {
    path: "/penyakit-ternak/detail/:id",
    component: () => import("../pages/penyakit-ternak/Detail.vue"),
  },
  //anak-ternak
  {
    path: "/anak-ternak/",
    component: () => import("../pages/anak-ternak/List.vue"),
  },
  {
    path: "/anak-ternak/create",
    component: () => import("../pages/anak-ternak/Form.vue"),
  },
  {
    path: "/anak-ternak/update/:id",
    component: () => import("../pages/anak-ternak/Update.vue"),
  },
  {
    path: "/anak-ternak/detail/:id",
    component: () => import("../pages/anak-ternak/Detail.vue"),
  },
  //peternak
  { path: "/peternak/", component: () => import("../pages/peternak/List.vue") },
  {
    path: "/peternak/create",
    component: () => import("../pages/peternak/Form.vue"),
  },
  {
    path: "/peternak/update/:id",
    component: () => import("../pages/peternak/Update.vue"),
  },
  {
    path: "/peternak/detail/:id",
    component: () => import("../pages/peternak/Detail.vue"),
  },

  {
    path: "/pengguna/",
    component: () => import("../pages/pengguna/List.vue"),
    meta: { requiredLevel: "admin" },
  },
  {
    path: "/pengguna/create",
    component: () => import("../pages/pengguna/Form.vue"),
    meta: { requiredLevel: "admin" },
  },
  {
    path: "/pengguna/update/:id",
    component: () => import("../pages/pengguna/Edit.vue"),
  },
  {
    path: "/pengguna/password/:id",
    component: () => import("../pages/pengguna/Password.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("userData");
  const userAceess = JSON.parse(isLoggedIn);

  const userLevel = userAceess ? userAceess.level : null;
  const requiredLevel = to.meta.requiredLevel;

  if (
    !isLoggedIn &&
    to.path !== "/login" &&
    to.path !== "/home" &&
    to.path !== "/"
  ) {
    next("/");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && userLevel !== requiredLevel) {
    if (!requiredLevel.includes(userLevel)) {
      next("/forbidden");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
