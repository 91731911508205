<template>
  <section class="vh-100" style="background-color: aliceblue">
    <div class="container-fluid h-custom">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-md-9 col-lg-6 col-xl-5 d-none d-sm-block">
          <img
            src="../assets/image-hero.png"
            class="img-fluid"
            alt="Sample image"
          />
        </div>
        <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
          <div class="m-2 bg-soft p-4 rounded-3">
            <div class="align-items-center justify-content-center text-center">
              <img style="width: 85px" src="../assets/logo.png" alt="" />
              <h4 class="fw-bolder mt-4">PETIR</h4>
              <h6 class="fw-bolder">Peternak Pintar Terintegrasi (PETIR)</h6>
            </div>

            <div class="divider d-flex align-items-center my-4 px-2 px-lg-4">
              <p class="text-center fw-bold mx-3 mb-0">LOGIN</p>
            </div>

            <form @submit.prevent="login" method="POST" class="px-2 px-lg-4">
              <div class="mb-3">
                <label class="form-label">Username</label>
                <input
                  v-model="username"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': formValidate.username,
                  }"
                  required
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Password</label>
                <input
                  v-model="password"
                  type="password"
                  class="form-control"
                  :class="{
                    'is-invalid': formValidate.password,
                  }"
                  required
                />
              </div>
              <div
                v-if="showAlert"
                class="alert alert-dismissible fade show"
                :class="{
                  '  alert-success': alert == 'success',
                  '  alert-danger': alert == 'error',
                }"
                role="alert"
              >
                <span>{{ message }}</span>
              </div>
              <div class="d-grid gap-2 mt-5 mb-lg-5">
                <button
                  type="submit"
                  class="btn btn-lg btn-primary"
                  :disabled="this.loading"
                >
                  <font-awesome-icon
                    v-if="loading"
                    icon="circle-notch"
                    :spin="loading"
                  />
                  LOGIN
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "LoginPage",
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      message: "",
      alert: "",
      showAlert: false,
      remember_me: false,
      formValidate: [],
    };
  },
  methods: {
    login() {
      this.loading = true;
      const formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);
      formData.append("remember", this.remember_me);

      axios
        .post("/v1/auth/login/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })

        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            if (response.data.status == true) {
              this.loading = false;
              this.showAlert = true;
              this.alert = "success";
              this.message = "Selamat Datang " + response.data.nama;
              setTimeout(() => {
                const userData = {
                  id: response.data.id,
                  nama: response.data.nama,
                  username: response.data.username,
                  level: response.data.level,
                  token: response.data.token,
                };
                localStorage.setItem("userData", JSON.stringify(userData));
                this.showAlert = false;
                this.$router.push("/dashboard");
              }, 2000);
            } else {
              this.loading = false;
              this.alert = "error";
              this.showAlert = true;
              this.message = response.data.message;
              setTimeout(() => {
                this.alert = "";
                this.showAlert = false;
              }, 2000);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>

<style scoped>
.bg-soft {
  background-color: #bfdef3;
}
.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: calc(100% - 73px);
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}
</style>