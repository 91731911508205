<template>
  <footer class="py-4 bg-light mt-auto">
    <div class="container-fluid px-4">
      <div class="d-flex align-items-center justify-content-between small">
        <div class="text-muted">
          Copyright &copy; 2024 Pemerintah Kabupaten Tojo Una-Una
        </div>
        <div>
          <img src="../assets/touna.png" alt="touna" style="height: 35px" />
          <!-- <a href="#">Privacy Policy</a>
          &middot;
          <a href="#">Terms &amp; Conditions</a> -->
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "FootBar",
};
</script>