<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-6 p-4">
          <h5>PETIR</h5>
          <span>Peternak Pintar Terintegrasi </span>
        </div>
        <div class="col-md-6 p-4">
          <h5>Tentang</h5>
          <span>
            Aplikasi Peternak Pintar Terintegrasi (PETIR). Laman ini
            diperuntukkan untuk mengelola data peternakan yang ada di Kabupaten
            Tojo Una-Una.
          </span>
        </div>
      </div>
    </div>
    <div class="copy">Copyright &copy; 2024 by IDETekno.com</div>
  </div>
</template>
<script>
export default {
  name: "FooterPage",
};
</script>
<style scoped>
.footer {
  background-color: #0b84a3;
  color: #fff;
  position: sticky;
  top: 100%;
}
.copy {
  border-top: solid 0.15em #fff;
  text-align: center;
  display: flex;
  align-items: center; /* Mengatur teks secara vertikal di tengah */
  justify-content: center; /* Mengatur teks secara horizontal di tengah */
  font-weight: light;
  font-size: 14px;
  padding: 20px;
  margin: 0 3em;
}
</style>