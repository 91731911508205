var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"vh-100",staticStyle:{"background-color":"aliceblue"}},[_c('div',{staticClass:"container-fluid h-custom"},[_c('div',{staticClass:"row d-flex justify-content-center align-items-center h-100"},[_vm._m(0),_c('div',{staticClass:"col-md-8 col-lg-6 col-xl-4 offset-xl-1"},[_c('div',{staticClass:"m-2 bg-soft p-4 rounded-3"},[_vm._m(1),_vm._m(2),_c('form',{staticClass:"px-2 px-lg-4",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Username")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.formValidate.username,
                },attrs:{"type":"text","required":""},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing)return;_vm.username=$event.target.value}}})]),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.formValidate.password,
                },attrs:{"type":"password","required":""},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),(_vm.showAlert)?_c('div',{staticClass:"alert alert-dismissible fade show",class:{
                '  alert-success': _vm.alert == 'success',
                '  alert-danger': _vm.alert == 'error',
              },attrs:{"role":"alert"}},[_c('span',[_vm._v(_vm._s(_vm.message))])]):_vm._e(),_c('div',{staticClass:"d-grid gap-2 mt-5 mb-lg-5"},[_c('button',{staticClass:"btn btn-lg btn-primary",attrs:{"type":"submit","disabled":this.loading}},[(_vm.loading)?_c('font-awesome-icon',{attrs:{"icon":"circle-notch","spin":_vm.loading}}):_vm._e(),_vm._v(" LOGIN ")],1)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-9 col-lg-6 col-xl-5 d-none d-sm-block"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/image-hero.png"),"alt":"Sample image"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"align-items-center justify-content-center text-center"},[_c('img',{staticStyle:{"width":"85px"},attrs:{"src":require("../assets/logo.png"),"alt":""}}),_c('h4',{staticClass:"fw-bolder mt-4"},[_vm._v("PETIR")]),_c('h6',{staticClass:"fw-bolder"},[_vm._v("Peternak Pintar Terintegrasi (PETIR)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"divider d-flex align-items-center my-4 px-2 px-lg-4"},[_c('p',{staticClass:"text-center fw-bold mx-3 mb-0"},[_vm._v("LOGIN")])])
}]

export { render, staticRenderFns }